var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1270px",
      "margin": ['0 auto', '16px auto'],
      "height": "auto"
    }
  }, [_c('c-flex', {
    attrs: {
      "display": ['none', 'flex'],
      "width": "100%",
      "align-items": "center",
      "margin-bottom": "20px",
      "gap": "48px"
    }
  }, [_c('c-button', {
    attrs: {
      "margin-top": "17.5px",
      "variant": "ghost",
      "display": "flex",
      "align-items": "center",
      "gap": "1rem"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_c('c-image', {
    attrs: {
      "w": "24px",
      "h": "24px",
      "object-fit": "cover",
      "src": require('@/assets/icon-chevron-left.svg')
    }
  }), _c('c-text', {
    attrs: {
      "font-size": "16px",
      "color": "primary.400",
      "font-weight": "500"
    }
  }, [_vm._v(" Kembali ")])], 1), _c('BreadcrumbPath', {
    attrs: {
      "paths": [{
        label: 'Beranda',
        href: '/'
      }, {
        label: 'Ketentuan Khusus Layanan',
        isCurrent: true
      }]
    }
  })], 1), _c('c-box', {
    attrs: {
      "background-color": "#FFF",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['1rem', '1.5rem'],
      "min-height": ['unset', '74vh']
    }
  }, [_c('c-box', {
    attrs: {
      "margin": "auto",
      "width": "100%",
      "overflow": "hidden"
    }
  }, [_c('c-box', {
    attrs: {
      "height": ['10000px', '5500px', '3700px']
    }
  }, [_c('iframe', {
    staticStyle: {
      "display": "block",
      "width": "100%",
      "height": "100%"
    },
    attrs: {
      "src": _vm.getIframeLink,
      "frameborder": "0"
    }
  })])], 1)], 1), _c('BackToTop', {
    attrs: {
      "visibleoffset": 300,
      "right": "70px"
    }
  }, [_c('c-box', {
    attrs: {
      "border-radius": "50%",
      "background-color": "white",
      "height": "50px",
      "width": "50px",
      "min-height": "50px",
      "min-width": "50px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-circle-arrow-top.svg'),
      "height": "100%",
      "width": "100%"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }