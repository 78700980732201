<template>
  <c-box
    width="100%"
    max-width="1270px"
    :margin="['0 auto', '16px auto']"
    height="auto"
  >
    <c-flex
      :display="['none', 'flex']"
      width="100%"
      align-items="center"
      margin-bottom="20px"
      gap="48px"
    >
      <c-button
        margin-top="17.5px"
        variant="ghost"
        display="flex"
        align-items="center"
        gap="1rem"
        @click="$router.back()"
      >
        <c-image
          w="24px"
          h="24px"
          object-fit="cover"
          :src="require('@/assets/icon-chevron-left.svg')"
        />
        <c-text
          font-size="16px"
          color="primary.400"
          font-weight="500"
        >
          Kembali
        </c-text>
      </c-button>

      <BreadcrumbPath
        :paths="[
          {
            label: 'Beranda',
            href: '/',
          },
          {
            label: 'Ketentuan Khusus Layanan',
            isCurrent: true
          },
        ]"
      />
    </c-flex>

    <c-box
      background-color="#FFF"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :padding="['1rem', '1.5rem']"
      :min-height="['unset', '74vh']"
    >
      <c-box
        margin="auto"
        width="100%"
        overflow="hidden"
      >
        <c-box
          :height="['10000px', '5500px', '3700px']"
        >
          <iframe
            :src="getIframeLink"
            frameborder="0"
            style="display:block; width:100%; height: 100%;"
          />
        </c-box>
      </c-box>
    </c-box>

    <BackToTop
      :visibleoffset="300"
      right="70px"
    >
      <c-box
        border-radius="50%"
        background-color="white"
        height="50px"
        width="50px"
        min-height="50px"
        min-width="50px"
      >
        <inline-svg
          :src="require('@/assets/icons/icon-circle-arrow-top.svg')"
          height="100%"
          width="100%"
        />
      </c-box>
    </BackToTop>
  </c-box>
</template>

<script>
import { ENV } from '@/constants/config'
import { CButton, CFlex, CImage, CText } from '@chakra-ui/vue'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import BackToTop from '@/components/back-to-top.vue'

export default {
  name: 'LayananEnterprisePage',
  components: { BackToTop, BreadcrumbPath, CFlex, CImage, CButton, CText },
  computed: {
    getIframeLink() {
      return `${ENV.URL_DIETELA_LANDING_PAGE}/policy?section=special-conditions-of-service`
    },
  },
}
</script>
